import React, { Fragment } from "react"
import Layout from "../components/layout"
import {
    StyledSection,
    StyledCardGridWrapper,
    StyledGrid,
    StyledHeadline,
} from "../styles/global"
import Card from "../components/card"
import { ILocation } from "../interface/location"
import styled from "styled-components"

const StyledArticleEntryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Forsikringindex: React.FC<{ location: ILocation }> = ({
    location,
}: any) => {
    const ingress = "Her finner du alt om forsikring og skadevern"
    const pagename = "Forsikring og skadevern"
    const contentIngress = { ingress, pagename }
    return (
        <Fragment>
            <Layout location={location} hero={true} ingress={contentIngress}>
                <StyledSection>
                    <StyledCardGridWrapper>
                        <StyledArticleEntryWrapper>
                            <StyledHeadline>
                                <h2 className="bbl-title">Forsikring</h2>
                                <h3 className="bbl-regular">
                                    Les mer om forsikring
                                </h3>
                            </StyledHeadline>

                            <StyledGrid>
                                <Card
                                    textOnly={true}
                                    text={"Totalforsikring"}
                                    path={`${location.pathname}/totalforsikring`}
                                />
                                <Card
                                    textOnly={true}
                                    text={"Pensjons- og personalforsikring"}
                                    path={`${location.pathname}/pensjonforsikring`}
                                />
                            </StyledGrid>
                        </StyledArticleEntryWrapper>
                        <StyledArticleEntryWrapper>
                            <StyledHeadline>
                                <h2 className="bbl-title">Skadevern</h2>
                                <h3 className="bbl-regular">
                                    Les mer om skadevern
                                </h3>
                            </StyledHeadline>
                            <StyledGrid>
                                <Card
                                    textOnly={true}
                                    text="Sikring mot brann"
                                    path={`${location.pathname}/sikring-mot-brann`}
                                />
                                <Card
                                    textOnly={true}
                                    text="Sikring mot vannskader"
                                    path={`${location.pathname}/sikring-mot-vann`}
                                />
                                <Card
                                    textOnly={true}
                                    text="Skadevern"
                                    path={`${location.pathname}/skadevern`}
                                />                                
                                <Card
                                    textOnly={true}
                                    text="Tips og råd"
                                    path={`${location.pathname}/tipsograd`}
                                />
                            </StyledGrid>
                        </StyledArticleEntryWrapper>
                    </StyledCardGridWrapper>
                </StyledSection>
            </Layout>
        </Fragment>
    )
}

export default Forsikringindex
